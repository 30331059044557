@use '@/styles/var/typography' as typography;

.title {
  @include typography.h3;
  margin-bottom: 0.625rem;
}

.description {
  @include typography.body1;
}

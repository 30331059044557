@use '@/styles/var/colors' as colors;

.dropdown-menu {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  min-width: 12rem;
  padding: 1rem;
  background-color: colors.$grey-white;
  box-shadow: 0 0.188rem 0.5rem 0 #0000001f;
  border-radius: 1rem;
  z-index: 9999;
}

.dropdown-trigger {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.dropdown-trigger-icon {
  &.open {
    transform: rotate(180deg);
  }

  &.active {
    path {
      fill: colors.$grey-primary;
    }
  }
}

@use '@/styles/var/colors' as colors;

.input {
  $size: 22.375rem;

  min-width: $size;
  width: $size;
  max-width: $size;
  background-color: colors.$grey-light-background;
}

@use '@/styles/var/typography' as typograhy;

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include typograhy.h5;
}

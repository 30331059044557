@use '@/styles/var/typography' as typography;

.title {
  @include typography.h3;
}

.opacity-animation {
  animation: fromOpacity 300ms;
}

@keyframes fromOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

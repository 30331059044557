@use '@/styles/var/colors' as colors;
@use '../table-styles' as table-styles;

.table-footer {
  border-bottom: table-styles.$border;
  border-left: table-styles.$border;
  border-right: table-styles.$border;
  background-color: colors.$grey-white;
  border-bottom-left-radius: table-styles.$border-radius;
  border-bottom-right-radius: table-styles.$border-radius;
}

@use '@/styles/var/ui' as ui;

.container {
  @include ui.page-container;

  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  max-height: 100%;
}

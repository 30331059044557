@use '@/styles/var/typography' as typograhy;
@use '@/styles/var/colors' as colors;
@use '@/styles/var/ui' as ui;

$width: 17.5rem;

.dropdown-menu {
  padding: 1rem;
  min-width: $width;
  width: $width;
  max-width: $width;
  z-index: 9999;
  background-color: colors.$grey-white;
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.5rem 0 #00000029;
  display: flex;
  flex-direction: column;
}

.all-universities-checkbox-container,
.accordion-item {
  &:not(:nth-last-child(-n + 1)) {
    border-bottom: 0.063rem solid colors.$grey-outline;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.all-universities-checkbox-label {
  @include typograhy.subtitle1;
}

.checkbox {
  padding: 0.5rem 0;
}

.submit-button {
  margin-top: 1rem;
}

@use '@/styles/var/colors' as colors;

/**
*  For "@reach/dialog"
*/
.overlay {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 15px;
  opacity: 0;
  animation: 0.2s cubic-bezier(0.4, 0.4, 0, 1) forwards fade-in;
  background-color: rgba(#000, 0.24);
  overflow: auto;
}

.content {
  opacity: 0;
  transform: scale(0.8);
  animation: 0.2s cubic-bezier(0.4, 0.4, 0, 1) forwards zoom-in;
  will-change: transform, opacity;
  padding: 0;
  max-width: 50vw;
  width: max-content;
  height: min-content;
  margin: auto;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

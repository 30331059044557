@use '@/styles/var/colors' as colors;

$width: 30.2rem;

.dropdown-menu {
  min-width: $width;
  width: $width;
  max-width: $width;
  z-index: 9999;
  background-color: colors.$grey-white;
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.5rem 0 #00000029;
  display: flex;
  flex-direction: column;
}

.actions-container {
  display: flex;
  grid-gap: 1rem;
  padding: 0.5rem;
  justify-content: flex-end;
}

@use '@/styles/var/typography' as typograhy;
@use '@/styles/var/colors' as colors;

.row {
  width: 100%;
}

.wrapper {
  position: sticky;
  top: 54px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  grid-gap: 8px;
  max-width: 30vw;
  width: 100%;
}

.title {
  @include typograhy.subtitle1;

  text-align: center;

  &.error {
    color: colors.$system-error;
  }
}

.description {
  @include typograhy.body2;
  color: colors.$grey-dark;
  opacity: 0.6;
  text-align: center;

  &.error {
    color: colors.$system-error;
  }
}

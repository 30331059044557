@use '@/styles/var/responsive' as responsive;

.filters {
  display: flex;
  grid-gap: 0.5rem;
  margin-top: 1rem;

  @include responsive.respond-to('small') {
    flex-direction: column;
    align-items: baseline;
  }

  &.is-visible {
    @include responsive.respond-to('small') {
      display: flex;
    }
  }

  @include responsive.respond-to('small') {
    width: 100%;
    display: none;
  }
}

.filter-button {
  display: none;
  margin: 0.5rem 0;
  grid-gap: 0.5rem;
  padding: 0;
  background: none;
  border: none;
  font-size: 1rem;

  @include responsive.respond-to('small') {
    display: flex;
    align-items: center;
    align-self: flex-start;
  }
}

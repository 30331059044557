@use '@/styles/var/colors' as colors;
@use '@/styles/var/typography' as typography;

.container {
  display: flex;
}

.pages-info {
  display: flex;
  align-items: center;
  margin-right: 1.625rem;
}

.dropdown-container {
  display: flex;
  align-items: center;
  margin-right: 1.625rem;
}

.rows-per-page {
  @include typography.caption1;
  color: colors.$grey-inactive;
  margin-right: 0.5rem;
}

.count {
  @include typography.caption1;
}

.arrow-actions {
  display: flex;
}

.back-action {
  rotate: 180deg;
}

.back-action,
.next-action {
  padding: 8px;
}

@use '@/styles/var/typography' as typography;
@use '@/styles/var/colors' as colors;

.link {
  @include typography.subtitle1;

  display: block;
  color: colors.$grey-primary;
  text-align: center;
  padding: 0.75rem 0;
}

@use '@/styles/var/responsive' as responsive;

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.5rem;

  @include responsive.respond-to('small') {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
  }
}

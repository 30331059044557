@use '@/styles/var/typography' as typography;
@use '@/styles/var/colors' as colors;
@use '@/styles/var/ui' as ui;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.form {
  max-width: 30rem;
  width: 100%;
  padding: 1.5rem;
  background-color: colors.$grey-white;
  border: 0.063rem solid colors.$grey-outline;
  border-radius: 1rem;
}

.title {
  @include typography.h3;
  margin-bottom: 1rem;
}

.email-input {
  margin-bottom: 1rem;
}

.password-input {
  margin-bottom: 0.25rem;
}

.link {
  @include ui.link;

  margin-left: 1rem;
}

.button {
  margin-top: 1rem;
}

.common-errors-container {
  margin-top: 1rem;
}

@use '@/styles/var/colors' as colors;
@use '@/styles/var/typography' as typography;
@use '@/styles/var/animations' as animations;

.button {
  @include typography.subtitle1;
  border: none;

  &.full-width {
    width: 100%;
  }

  &:not(:disabled) {
    cursor: pointer;
    transition: opacity ease-in animations.$transition-time;

    &:hover {
      opacity: 0.9;
    }
  }
}

.contained {
  &.black {
    color: colors.$grey-white;
    background-color: colors.$grey-primary;

    &:disabled {
      background-color: colors.$grey-disable;
      color: colors.$grey-inactive;
    }
  }

  &.large {
    padding: 0.75rem;
    border-radius: 0.75rem;
  }

  &.medium {
    @include typography.subtitle2;

    text-align: center;
    padding: 0.5rem;
    border-radius: 0.625rem;
  }
}

.text {
  background-color: transparent;

  &.black {
    color: colors.$grey-primary;
  }

  &:not(.no-padding).large {
    padding: 0.75rem 0;
    border-radius: 0.75rem;
  }
}

.icon {
  border: none;
  background-color: transparent;
  padding: 0;
  line-height: 0;
  border-radius: 50%;

  &:not(:disabled) {
    cursor: pointer;
    transition: opacity ease-in animations.$transition-time;

    &:hover {
      opacity: 0.8;
    }
  }

  &:disabled {
    opacity: 0.7;
  }
}

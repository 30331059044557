@use '@/styles/var/animations' as animations;
@use '@/styles/var/typography' as typography;

.navigation {
  width: max-content;
  margin: 1rem 0;
}

.navigation-link {
  @include typography.h4;

  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  transition: opacity ease-in animations.$transition-time;

  &:hover {
    opacity: 0.7;
  }
}

.navigation-link-icon {
  rotate: 90deg;
}

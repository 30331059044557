@use '@/styles/var/typography' as typography;
@use '@/styles/var/colors' as colors;

.container {
  display: flex;
  grid-gap: 0.25rem;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.title {
  @include typography.caption1;

  color: colors.$grey-secondary;
}

.text {
  @include typography.body1;
}

@use '@/styles/var/colors' as colors;

.wrapper {
  &:global([data-reach-dialog-content]) {
    margin: auto;
    background-color: transparent;
    box-shadow: none;
  }
}

.scrollWrapper {
  display: grid;
  grid-template-rows: auto min-content;
}

.contentWrapper {
  max-width: 35rem;
  border-radius: 1rem;
  overflow-y: auto;
  background-color: colors.$grey-white;
  height: max-content;
  margin: auto;
}

.internalScroll {
  max-height: 32.75rem;
  overflow: hidden;
}

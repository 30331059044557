@use '@/styles/var/ui' as ui;

.container {
  display: flex;
}

.text {
  &.ellipsis {
    @include ui.text-ellipsis;
  }
}

@use './colors' as colors;

$font-family: 'Montserrat';

@mixin h1 {
  font-family: $font-family;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.5rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@mixin h2 {
  font-family: $font-family;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@mixin h3 {
  font-family: $font-family;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@mixin h4 {
  font-family: $font-family;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@mixin h5 {
  font-family: $font-family;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@mixin subtitle1 {
  font-family: $font-family;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@mixin subtitle2 {
  font-family: $font-family;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
  color: colors.$dark;
}

@mixin body1 {
  font-family: $font-family;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@mixin body2 {
  font-family: $font-family;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@mixin caption1 {
  font-family: $font-family;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@mixin caption2 {
  font-family: $font-family;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0em;
  color: colors.$dark;
}

@use '@/styles/var/colors' as colors;
@use '../table-styles' as table-styles;

.table-body-row {
  display: flex;
  justify-content: space-between;
  background-color: colors.$grey-white;
  border-bottom: table-styles.$border;
  border-left: table-styles.$border;
  border-right: table-styles.$border;

  width: 100%;
  vertical-align: middle;
}

.avatar {
  border-radius: 50%;
}

.avatar-image {
  line-height: 0;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

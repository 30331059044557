@use '@/styles/var/typography' as typograhy;
@use '@/styles/var/colors' as colors;
@use '@/styles/var/ui' as ui;

.container {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

$size: 1.25rem;

.checkbox-container {
  position: relative;
  line-height: 0;
}

.checkbox {
  position: relative;
  z-index: 9;
  min-width: $size;
  width: $size;
  max-width: $size;
  min-height: $size;
  height: $size;
  max-height: $size;
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0.063rem solid colors.$grey-outline;
  border-radius: 0.375rem;
  cursor: pointer;
  background-color: transparent;

  &.checked {
    border: 0.063rem solid colors.$grey-dark;
  }
}

.checkbox-icon {
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: colors.$grey-dark;
  border-radius: 0.375rem;

  path {
    fill: colors.$grey-white;
  }
}

.checkbox-label {
  @include typograhy.body2;

  cursor: pointer;
  margin-top: 2px;

  &.checked {
    font-weight: 500;
  }
}

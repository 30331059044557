@use '@/styles/global/reset' as *;
@use '@/styles/var/typography' as typograhy;

* {
  box-sizing: border-box;
}

body {
  @include typograhy.body1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

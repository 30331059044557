@use '@/styles/var/colors' as colors;

.container {
  max-width: 30rem;
  width: 100%;
  padding: 1.5rem;
  background-color: colors.$grey-white;
  border: 0.063rem solid colors.$grey-outline;
  border-radius: 1rem;
}

.icon-container {
  display: inline-block;
  padding: 0.75rem;
  line-height: 0;
  border-radius: 50%;
  margin-bottom: 1rem;

  &.success {
    background-color: rgba(colors.$system-success, 0.1);
  }

  &.failure {
    background-color: rgba(colors.$system-error, 0.1);

    svg {
      path {
        stroke: colors.$system-error;
      }
    }
  }
}

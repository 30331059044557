@use '@/styles/var/colors' as colors;

.full-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(colors.$grey-outline, 0.3);
  z-index: 999;
  border-radius: 10px;
}

$dark: #272931;

$grey-primary: #25252d;
$grey-secondary: #7c7c81;
$grey-inactive: #b9b9bc;
$grey-outline: #dcdcdd;
$grey-disable: #e4e4e6;
$grey-background: #f6f6f7;
$grey-light-background: #fbfbfb;
$grey-white: #ffffff;
$grey-dark: #1c2536;

$system-accent: #276ef1;

$system-success-dark: #276ef1;
$system-success: #499f68;
$system-success-light: #e8f3ec;

$system-error-dark: #c74940;
$system-error: #dd5147;

$system-warning: #ffaf21;
$system-warning-dark: #e69e1e;

@use '@/styles/var/typography' as typography;
@use '@/styles/var/colors' as colors;
@use '@/styles/var/ui' as ui;

.form {
  max-width: 30rem;
  width: 100%;
  padding: 1.5rem;
  background-color: colors.$grey-white;
  border: 0.063rem solid colors.$grey-outline;
  border-radius: 1rem;
}

.password-input {
  margin-bottom: 1rem;
}

.common-errors-container {
  margin-top: 1rem;
}

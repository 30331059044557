@use '@/styles/var/colors' as colors;
@use '@/styles/var/typography' as typography;
@use '@/styles/var/animations' as animations;
@use '@/styles/var/ui' as ui;
@use '@/styles/var/responsive' as responsive;

.header {
  @include ui.container;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: colors.$grey-white;
  border-bottom: 0.063rem solid colors.$grey-outline;

  @include responsive.respond-to('medium') {
    padding: 1em 1.5rem;
    flex-direction: column;
  }
}

.header-logo {
  display: flex;

  @include responsive.respond-to('medium') {
    width: 100%;
    justify-content: space-between;
  }
}

.menu-logo {
  line-height: 0;
}

.menu-icon {
  line-height: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: none;

  @include responsive.respond-to('medium') {
    display: block;
  }
}

.navigation {
  display: flex;

  @include responsive.respond-to('medium') {
    flex-direction: column;
    &.is-mobile {
      display: none;
    }
  }
}

.list {
  display: flex;
  grid-gap: 2.5rem;
  align-items: center;

  @include responsive.respond-to('medium') {
    grid-gap: 1rem;
    flex-direction: column;
  }

  .list-item {
    .list-item-link {
      @include typography.subtitle1;

      transition: opacity ease-in animations.$transition-time;
      text-decoration: none;
      color: colors.$grey-secondary;
      background-color: transparent;
      padding: 0;

      &.active {
        color: colors.$grey-primary;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.logout-button {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  padding: 0;
  margin-left: 1.5em;

  @include responsive.respond-to('medium') {
    margin: 1em auto 0;
  }

  .logout-button-icon {
    line-height: 0;
  }

  .logout-button-label {
    color: colors.$system-error;
  }
}

@use '@/styles/var/colors' as colors;
@use '@/styles/var/typography' as typography;

.kebab-menu-dropdown {
  display: flex;
  flex-direction: column;
  width: max-content;
  background: colors.$grey-white;
  border: 1px solid #acc5c5;
  box-shadow: 0 5px 15px rgba(17, 66, 75, 0.08);
  border-radius: 6px;
  z-index: 999;
  overflow: hidden;
}

@mixin kebab-menu-item {
  @include typography.caption1;

  cursor: pointer;
  border: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.2rem 0.5rem;
  min-width: 60px;
}

.kebab-menu-dropdown-item {
  @include kebab-menu-item;

  &.kebab-menu-dropdown-item-selected {
    font-weight: 700;
    background-color: colors.$grey-white;
  }

  &:hover {
    background-color: colors.$grey-background;
  }
}

.kebab-menu-dropdown-item-loading {
  @include kebab-menu-item;
}

.dropdown-indicator {
  display: flex;
  grid-gap: 2px;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: unset;
  }
}

.dropdown-indicator-label {
  @include typography.caption1;
}

.dropdown-indicator-icon {
  $size: 16px;
  max-width: $size;
  width: $size;
  min-width: $size;
  max-height: $size;
  height: $size;
  min-height: $size;

  &.open {
    transform: rotate(180deg);
  }
}

@use '@/styles/var/colors' as colors;
@use '@/styles/var/typography' as typography;

.chip {
  @include typography.subtitle2;

  width: max-content;
  padding: 0.5rem 0.75rem;
  border-radius: 1.25rem;
  height: max-content;

  &.info {
    background-color: colors.$grey-background;
    border: 0.063rem solid colors.$grey-outline;
  }

  &.success {
    background-color: colors.$system-success;
    border: 0.063rem solid colors.$system-success;
    color: colors.$grey-white;
  }

  &.error {
    background-color: colors.$system-error;
    border: 0.063rem solid colors.$system-error;
    color: colors.$grey-white;
  }
}

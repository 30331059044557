.container {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  padding: 1rem;
}

.footer {
  display: flex;
  grid-gap: 1rem;
  justify-content: flex-end;
}

@use '@/styles/var/typography' as typography;

.container {
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
}

.icons-container {
  display: flex;
  grid-gap: 0.5rem;
  line-height: 0;
}

.rating {
  @include typography.subtitle1;
}

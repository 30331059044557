@use '@/styles/var/colors' as colors;
@use '../table-styles' as table-styles;

.table-head {
  min-width: max-content;
  width: 100%;
  border: 0.063rem solid colors.$grey-outline;
  border-top-right-radius: table-styles.$border-radius;
  border-top-left-radius: table-styles.$border-radius;
  background-color: colors.$grey-light-background;
  display: flex;
  justify-content: space-between;
}

@use '@/styles/var/typography' as typograhy;
@use '@/styles/var/ui' as ui;

.container {
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
}

.full-name {
  @include typograhy.body2;

  &.ellipsis {
    @include ui.text-ellipsis;
  }
}

@use '@/styles/var/typography' as typograhy;
@use '@/styles/var/animations' as animations;
@use '@/styles/var/colors' as colors;

.button {
  @include typograhy.subtitle2;

  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 4px;
  width: 100%;

  &:disabled {
    opacity: 1;
  }

  &:not(:disabled):hover {
    .icon {
      &.none {
        opacity: 1;

        &:not(.desc-first) {
          transform: rotate(180deg);
        }
      }
    }
  }

  .icon {
    &.asc {
      transform: rotate(180deg);
    }

    &.none {
      opacity: 0;
    }
  }
}

.table-container {
  width: 100%;
  overflow: auto;

  &.empty {
    overflow: hidden;
  }
}

.table {
  position: relative;
  min-width: max-content;
  width: 100%;
}

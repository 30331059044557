@use '@/styles/var/typography' as typography;
@use '@/styles/var/colors' as colors;
@use '@/styles/var/ui' as ui;
@use '../table-styles' as table-styles;

.table-head {
  @include typography.subtitle2;
  @include ui.text-ellipsis;
  @include table-styles.cell-box;
}

@use '@/styles/var/responsive' as responsive;

.button {
  display: flex;
  grid-gap: 0.5rem;
  margin-top: auto;

  @include responsive.respond-to('large') {
    .button-text {
      display: none;
    }
  }

  @include responsive.respond-to('medium') {
    width: auto;
    max-width: auto;

    .button-text {
      display: inline;
    }
  }

  @include responsive.respond-to('small') {
    position: absolute;
    top: 0.5rem;
    right: 0;
    padding: 0.25rem 0.75rem !important;
  }
}

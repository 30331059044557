@use '@/styles/var/colors' as colors;
@use '@/styles/var/typography' as typography;
@use '@/styles/var/animations' as animations;

.container {
  display: flex;
  flex-direction: column;
}

.input-container {
  position: relative;
  width: 100%;
}

.label {
  @include typography.caption1;

  color: colors.$grey-secondary;
  position: absolute;
  top: -0.5rem;
  left: 0.75rem;
  background-color: colors.$grey-white;
  padding: 0 0.25rem;
}

.input {
  @include typography.body1;

  padding: 0.688rem 1rem;
  width: 100%;
  border-radius: 0.75rem;
  background-color: colors.$grey-white;
  border: 0.063rem solid colors.$grey-outline;
  outline: 0;
  transition: border ease-in animations.$transition-time;

  &.hide-arrows-from-number-input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &:focus {
    border: 0.063rem solid colors.$grey-primary;
  }

  &::placeholder {
    color: colors.$grey-secondary;
  }

  &.has-error {
    border: 0.063rem solid colors.$system-error-dark;
  }

  &.start-icon-shown {
    padding-left: 2.7rem;
  }

  &.end-icon-shown {
    padding-right: 2.5rem;
  }
}

@mixin icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: max-content;
  height: max-content;
  line-height: 0;

  &:hover {
    opacity: 0.6;
  }
}

.start-icon {
  @include icon;
  left: 0.75rem;
}

.end-icon {
  @include icon;
  right: 0.75rem;
}

.error-list,
.description {
  margin-top: 0.438rem;
}

.description {
  @include typography.body2;
}
